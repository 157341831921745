import React, { useEffect } from 'react'
import { Script, ScriptStrategy } from 'gatsby'
import { configureScope } from '@sentry/gatsby'
import { useHeapEvents } from '@toasttab/shared/src/hooks/useHeapEvents'

const PageElement = ({ children }: any) => {
  useHeapEvents('isCustomer', 'isCustomer')

  return <>{children}</>
}

export default PageElement
