import { useEffect } from 'react'
import _helpers from '@toasttab/shared/src/globals/_helpers'
import heapAnalytics from '@toasttab/shared/utils/heapAnalytics'

export const useHeapEvents = (cookieName: string, heapEventName: string) => {
  useEffect(() => {
    const heapCookie = _helpers.getCookie(cookieName) || ''
    if (heapCookie !== '') {
      heapAnalytics({ [heapEventName]: heapCookie })
    }
  }, [])
}
