import React from 'react'
import { I18NextContext } from '../@types/types'

export const I18nextContext = React.createContext<I18NextContext>({
  language: 'en',
  languages: ['en'],
  region: 'US',
  routed: false,
  defaultLanguage: 'en',
  generateDefaultLanguagePage: false,
  originalPath: '/',
  path: '/',
  prefix: '/',
  localized: false
})
