import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const RootElement = ({ children }: any) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default RootElement
