import '@toasttab/main/src/assets/styles/global.css'
import 'lazysizes'
import React from 'react'
import RootElement from './src/components/root-element'
// import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { wrapPageElement as SharedWrapPageElement } from '@toasttab/shared/src/page/wrapPageElement'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'

export const wrapRootElement = ({ element }) => {
  const enableLiveUpdates = process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_ENABLE_LIVE_UPDATES || true
  const enableInspectorMode = process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_ENABLE_INSPECTOR_MODE || true
  const locale = process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_DEFAULT_LOCALE || 'en'
  const debugMode = process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW_DEBUG_MODE || true

  const App = () => (
    <ContentfulLivePreviewProvider
      locale={String(locale)}
      enableInspectorMode={Boolean(enableInspectorMode)}
      enableLiveUpdates={Boolean(enableLiveUpdates)}
      debugMode={Boolean(debugMode)}
    >
      <RootElement>{element}</RootElement>
    </ContentfulLivePreviewProvider>
  )
  // const LDApp = withLDProvider({ clientSideID: '64384aba5a7cac13c8150bf8' })(App)
  return <App />
}

export const wrapPageElement = SharedWrapPageElement
