declare global {
  interface Window {
    heap: any
  }
}

const heapAnalytics = (heapObject: Object) => {
  if (typeof window !== 'undefined') {
    const heapHandler = window.heap
    let dataPending = true
    const heapTimeout = () => {
      if (typeof heapHandler !== 'undefined' && dataPending) {
        heap.addEventProperties(heapObject)
        heap.addUserProperties(heapObject)
      } else {
        setTimeout(heapTimeout, 1000)
      }
    }
    heapTimeout()
  }
}

export default heapAnalytics
